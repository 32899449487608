


































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import SiteLayout from '@/shared/components/site/SiteLayout.vue';
import GridLayout from '@/shared/components/builder/GridLayout.vue';
import Intermission from '@/shared/components/intermission/Intermission.vue';
import { BUILDER_SOURCE_SEARCH, Draft } from '@/shared/store/onboarding';
import { UserAudio, UserImage } from '@/shared/gen/messages.pisa';
import { getUGC } from '@/shared/lib/ugc_adaptor';
import MusicIndustry from '@/shared/lib/music_industry';

@Component({
  mixins: [],
  components: {
    GridLayout,
    SiteLayout,
    Intermission,
  },
  validations: {},
})
export default class IntermissionView extends Vue {
  draftLoaded: boolean = false;

  isLoading: boolean = false;

  @Getter('onboarding/getDraft') draft: Draft;

  @Getter('onboarding/getDraftImages') selectedImages: UserImage[];

  @Getter('onboarding/getNumDraftAudio') numDraftAudio: Number;

  @Getter('onboarding/getDraftAudio') draftUserAudio: UserAudio[];

  @Getter('recommendedAds/getImagesByThemeForAds') imagesByTheme: any;

  get artistName() {
    return getUGC('artistName');
  }

  get releaseName() {
    return getUGC('releaseName');
  }

  get releaseType() {
    return getUGC('releaseType');
  }

  get releaseGenre() {
    return getUGC('releaseGenre');
  }

  get releaseLandingPages() {
    if (this.draft) {
      return this.draft.landingPages;
    }
    return [];
  }

  get imageUrl() {
    return this.draft.imageURL;
  }

  beforeCreate() {
    this.isLoading = true;
    this.$store.commit('recommendedAds/setProcessing', true);
    let id: string = '0';
    if (this.$route.params.id) {
      ({ id } = this.$route.params);
    } else if (this.draft.id) {
      ({ id } = this.draft);
    }
    this.$store.dispatch('onboarding/load', id).finally(() => {
      this.draftLoaded = true;
      let params = {};
      if (this.draft.id) {
        params = {
          id: this.draft.id,
        };
      }
      if (!MusicIndustry.isValid(this.draft, 'intermission', params)) {
        const redirect = MusicIndustry.getAppropriateStep(this.draft, 'intermission', params);
        this.$router.replace(redirect);
      } else {
        this.$store.dispatch('mixpanel/trackOnce', {
          properties: {
            releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
            releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
            domainUrl: this.$store.getters['onboarding/getLandingPageDomains'],
            onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
            numberUserImagesApplied: this.$store.getters['onboarding/getNumDraftImages'],
            hasAudioApplied: this.$store.getters['onboarding/draftHasAudio'],
            referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
          },
          action: 'Zire.BuildingAdsOnboardingStepStarted',
        });
        this.$store.dispatch('onboarding/createPreviewPromise').then(() => {
          this.$store.dispatch('recommendedAds/regenerate', { campaignId: this.draft.id });
        });
      }
    });
  }

  intermissionComplete() {
    this.$store.dispatch('mixpanel/trackOnce', {
      properties: {
        releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
        releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
        domainUrl: this.$store.getters['onboarding/getLandingPageDomains'],
        onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
        numberUserImagesApplied: this.$store.getters['onboarding/getNumDraftImages'],
        hasAudioApplied: this.$store.getters['onboarding/draftHasAudio'],
        referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
      },
      action: 'Zire.BuildingAdsOnboardingStepComplete',
    });
    this.$router.replace({
      name: 'recommended-ads',
      params: {
        id: this.draft.id,
      },
    });
  }
}
