












































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import WaveSurfer from 'wavesurfer.js';
// @ts-ignore
import RegionPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min';

import HeaderBlock from '@/shared/components/intermission/HeaderBlock.vue';
import ProgressDetails from '@/shared/components/intermission/ProgressDetails.vue';
import { Operation_Status as OperationStatus } from '@/shared/gen/messages.pisa';

@Component({
  mixins: [],
  components: {
    HeaderBlock,
    ProgressDetails,
  },
})
export default class Audio extends Vue {
  @Prop({ default: false })
  active: boolean;

  @Prop({ default: '' })
  artistName: string;

  @Prop({ default: '' })
  releaseName: string;

  @Prop({ default: '' })
  releaseType: string;

  @Prop({ default: '' })
  releaseGenre: string;

  @Prop({ default: () => [] })
  releaseLandingPages: any[];

  @Prop({ required: true })
  audioSourceUrl: string;

  @Prop({ default: 0 })
  startTimeMs: number;

  wavesurfer: WaveSurfer;

  @Getter('audioLibrary/getCurrentStatus') currentAudioStatus: string | null;

  hasRun: boolean = false;

  get isRunning() {
    if ((!this.hasRun && this.active)) {
      return true;
    }
    return false;
  }

  get isComplete() {
    return this.playableComplete && this.feedbackComplete;
  }

  get progressDetailsArePaused() {
    switch (this.currentAudioStatus) {
      case OperationStatus.PENDING:
        return true;
      case OperationStatus.PROCESSING:
        return false;
      case OperationStatus.COMPLETED:
        return false;
      default:
        return false;
    }
  }

  get currentOperationText() {
    switch (this.currentAudioStatus) {
      case OperationStatus.PENDING:
        return 'Preparing Audio';
      case OperationStatus.PROCESSING:
        return 'Creating Preview';
      default:
        return 'Building Ads';
    }
  }

  playableComplete: boolean = false;

  feedbackComplete: boolean = true;

  markPlayableComplete() {
    this.playableComplete = true;
    this.checkAllComplete();
  }

  // markFeedbackComplete() {
  //   this.feedbackComplete = true;
  //   this.checkAllComplete();
  // }

  checkAllComplete() {
    if (this.playableComplete && this.feedbackComplete) {
      setTimeout(() => {
        this.hasRun = true;
        this.$emit('processing-complete');
      }, 1500);
    }
  }

  loaded() {}

  mounted() {
    this.$nextTick(() => {
      this.renderWaveform();
    });
  }

  renderWaveform() {
    this.wavesurfer = WaveSurfer.create({
      container: '#waveform',
      progressColor: '#42FFD6',
      height: 64,
      // maxCanvasWidth: 640,
      cursorColor: 'transparent',
      minPxPerSec: 10,
      pixelRatio: 1,
      normalize: true,
      interact: false,
      backend: 'MediaElement',
      responsive: true,
      plugins: [
        RegionPlugin.create(),
      ],
    });
    this.wavesurfer.load(this.audioSourceUrl);
    this.wavesurfer.on('ready', () => {
      this.$nextTick(() => {
        const startTime: number = Math.round(this.startTimeMs / 1000);
        this.wavesurfer.addRegion({
          id: 'preview',
          start: startTime,
          end: startTime + 30,
          color: 'rgba(66, 255, 214, 0.5)',
          regionHighlight: true,
          drag: false,
          resize: false,
          attributes: { highlight: true },
        });
      });
    });
  }

  beforeDestroy() {}
}
