


























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

import SiteLayout from '@/shared/components/site/SiteLayout.vue';
import SingleRetailerEntry from '@/shared/components/onboarding/SingleRetailerEntry.vue';
import GridLayout from '@/shared/components/builder/GridLayout.vue';
import OnboardingNavigation from '@/shared/components/onboarding/OnboardingNavigation.vue';
import SelectDropdown from '@/shared/components/common/SelectDropdown.vue';
import MaterialInputField from '@/shared/components/form/MaterialInputField.vue';
import { BUILDER_SOURCE_MANUAL, BUILDER_SOURCE_SEARCH, Draft } from '@/shared/store/onboarding';
import { getUGC } from '@/shared/lib/ugc_adaptor';

@Component({
  mixins: [validationMixin],
  components: {
    GridLayout,
    SiteLayout,
    SingleRetailerEntry,
    OnboardingNavigation,
    SelectDropdown,
    MaterialInputField,
  },
  validations: {
    artistName: { required },
    releaseName: { required },
    releaseGenre: { required },
    releaseType: { required },
  },
})
export default class AboutYourRelease extends Vue {
  $refs!: {
    releaseGenreInput: any,
    releaseTypeInput: any,
    artistNameInput: any,
  };

  errors: any = {
    artistName: [],
    releaseName: [],
    releaseGenre: [],
    releaseType: [],
  };

  @Prop({ default: '0' })
  id: string;

  artistName: string = '';

  releaseName: string = '';

  releaseGenre: string = '';

  releaseType: string = '';

  releaseTypeOptions: string[] = ['Single', 'EP', 'Album'];

  @Getter('industry/genreOptions') genreOptions: string[];

  @Getter('onboarding/getDraft') draftState: Draft;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  get nextNavigationText() {
    return 'Add Link';
  }

  get mobileReleaseTypeOptions() {
    const mobileReleaseTypeOptions: any[] = [];
    this.releaseTypeOptions.forEach((releaseType) => {
      mobileReleaseTypeOptions.push({
        text: releaseType,
        disabled: false,
        selected: (releaseType === this.releaseType),
      });
    });
    return mobileReleaseTypeOptions;
  }

  loadDraft(id: string) {
    if (id && (id !== '0')) {
      this.$store.dispatch('onboarding/load', id).then(() => {
        // const currentDraft = this.draftState;

        this.artistName = getUGC('artistName');
        this.releaseName = getUGC('releaseName');
        this.releaseGenre = getUGC('releaseGenre');
        this.releaseType = getUGC('releaseType');
      });
    } else {
      this.artistName = getUGC('artistName');
      this.releaseName = getUGC('releaseName');
      this.releaseGenre = getUGC('releaseGenre') || '';
      this.releaseType = getUGC('releaseType') || '';
    }
  }

  created() {
    this.$store.dispatch('mixpanel/trackOnce', {
      properties: {
        referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
      },
      action: 'Zire.ManualReleaseDetailsStepStarted',
    });
  }

  beforeMount() {
    this.$store.dispatch('industry/loadConfigIfNecessary');
    this.loadDraft(this.id);
  }

  mounted() {
    // make sure the mobile dsp search is closed
    if (this.isMobile) {
      this.$root.$emit('bv::hide::modal', 'modal-dsp-search');
    }
  }

  handlePrevClicked() {
    this.$router.replace({
      name: 'new-campaign',
    });
  }

  handleNextClicked() {
    if (
      (this.$v.$anyError)
      || (this.artistName === '' || this.releaseName === '' || this.releaseType === '' || this.releaseGenre === '')
    ) {
      this.$v.$touch();
      return;
    }
    this.onSubmit();
  }

  onSubmit() {
    /*
    write local payload to draft
    save draft
     */
    // evt.preventDefault();

    const fieldElements = [
      {
        key: 'artistName',
        value: this.artistName,
      }, {
        key: 'releaseName',
        value: this.releaseName,
      }, {
        key: 'releaseGenre',
        value: this.releaseGenre,
      }, {
        key: 'releaseType',
        value: this.releaseType,
      }];
    fieldElements.forEach((fieldValue) => {
      this.$store.dispatch('onboarding/updateUGC', {
        Key: fieldValue.key,
        Value: fieldValue.value,
      });
    });
    this.$store.dispatch('onboarding/setBuilderSourceType', BUILDER_SOURCE_MANUAL);
    this.$store.dispatch('onboarding/setAccessedThrough', BUILDER_SOURCE_MANUAL);

    this.$store.dispatch('mixpanel/trackOnce', {
      properties: {
        releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
        releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
        onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
        referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
      },
      action: 'Zire.ManualReleaseDetailsStepCompleted',
    });
    this.$router.push({
      name: 'release-link',
      params: {
        id: this.draftState.id,
      },
    });
  }

  blurWhenSelected(elementId: string) {
    const elem = document.getElementById(elementId) || null;
    if (elem) {
      elem.blur();
    }
  }

  delayTouchUntilBlur($v: any) {
    $v.$reset();
  }

  touchElement($v: any) {
    $v.$touch();
  }

  resetElement($v: any) {
    $v.$reset();
  }

  get submitDisabled() {
    return (this.$v.$anyError || !this.$v.$anyDirty);
  }

  get submitHighlighted() {
    return !(this.$v.$anyError && this.$v.$anyDirty)
      && (this.artistName !== '' && this.releaseName !== '' && this.releaseType !== '' && this.releaseGenre !== '');
  }
}
