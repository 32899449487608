




























































import { Component, Prop, Vue } from 'vue-property-decorator';

import ProgressLineComponent from '@/shared/components/common/ProgressLineComponent.vue';

@Component({
  mixins: [],
  components: {
    ProgressLineComponent,
  },
})
export default class ProgressDetails extends Vue {
  @Prop({ default: 0 })
  ttl: string;

  @Prop({ default: 'progress-bar-component' })
  elementId: string;

  @Prop({ default: '' })
  completeText: string;

  @Prop({ default: '' })
  placeholderCopy: string;

  @Prop({ default: '#4CD964' })
  color: string;

  @Prop({ default: '#0b4f69' })
  trailColor: string;

  @Prop({ default: () => [] })
  processingText: string[];

  @Prop({ default: () => [] })
  itemList: string[];

  @Prop({ default: -1 })
  pctCompleteProp: number;

  @Prop({ default: false })
  isPaused: boolean;

  pctComplete: number = 0;

  numSteps: number = 0;

  intervalTimeMs: number = 100;

  intervalID: number;

  get timeToLiveNumber() {
    return parseInt(this.ttl, 10);
  }

  get currentItem() {
    if (this.itemList.length > 0 && this.pctComplete > 0) {
      const indexPosition = Math.floor((this.pctComplete / 100) * this.itemList.length);
      if (indexPosition >= this.itemList.length) {
        return '';
      }
      return this.itemList[indexPosition];
    }
    return '';
  }

  get progressDetails() {
    if (this.processingText.length > 0) {
      const indexPosition = Math.floor((this.pctComplete / 100) * this.processingText.length);
      if (indexPosition >= this.processingText.length) {
        return '';
      }
      return this.processingText[indexPosition];
    }
    return '';
  }

  loaded() {}

  updated() {
    if (this.pctCompleteProp >= 0) {
      this.pctComplete = this.pctCompleteProp;
      if (this.pctCompleteProp >= 100) {
        this.$emit('completed-processing');
      }
    }
  }

  beginProcessing() {
    if (this.pctCompleteProp < 0) {
      this.numSteps = this.timeToLiveNumber / this.intervalTimeMs;
      this.intervalID = setInterval(this.updateProcessing, this.intervalTimeMs);
    }
  }

  updateProcessing() {
    if (this.pctComplete < 100) {
      if (!this.isPaused) {
        this.pctComplete += (100 / this.numSteps);
        this.pctComplete = Math.min(this.pctComplete, 100);
      }
    } else {
      clearInterval(this.intervalID);
      this.$emit('completed-processing');
    }
  }

  mounted() {
    const jiggleFactor = Math.random() * 1000 + 500;
    window.setTimeout(this.beginProcessing, jiggleFactor);
  }

  beforeDestroy() {}
}
