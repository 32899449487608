









































































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { UserImage } from '@/shared/gen/messages.pisa';
import { getUGC } from '@/shared/lib/ugc_adaptor';
import { BUILDER_SOURCE_MANUAL, BUILDER_SOURCE_SEARCH, Draft } from '@/shared/store/onboarding';

import GridLayout from '@/shared/components/builder/GridLayout.vue';
import ImageColorPalette from '@/shared/components/onboarding/ImageColorPalette.vue';
import ImageLibraryModal from '@/shared/components/builder/ImageLibraryModal.vue';
import ImageOverlay from '@/shared/components/builder/ImageOverlay.vue';
import LoadingIndicator from '@/shared/components/common/LoadingIndicator.vue';
import OnboardingNavigation from '@/shared/components/onboarding/OnboardingNavigation.vue';
import OpenImageLibraryButton from '@/shared/components/builder/OpenImageLibraryButton.vue';
import AddImagesButton from '@/shared/components/builder/AddImagesButton.vue';
import SelectedImageWithLaser from '@/shared/components/onboarding/SelectedImageWithLaser.vue';
import SiteLayout from '@/shared/components/site/SiteLayout.vue';
import { ImageLibrarySelected } from '@/shared/store/recommended_ads';

const reducedImagesDisplayDesktopHeight = 740;

@Component({
  mixins: [],
  components: {
    GridLayout,
    ImageColorPalette,
    ImageLibraryModal,
    ImageOverlay,
    LoadingIndicator,
    OnboardingNavigation,
    OpenImageLibraryButton,
    AddImagesButton,
    SelectedImageWithLaser,
    SiteLayout,
  },
})
export default class FeatureImages extends Vue {
  @Getter('onboarding/getDraft') draft: Draft;

  @Getter('onboarding/getBuilderSource') builderSource: string | undefined;

  @Getter('onboarding/getDraftImages') selectedImages: UserImage[];

  @Getter('onboarding/getIsImageProcessed') getIsImageProcessed: Function;

  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  get isMobile() {
    return this.$store.getters['layout/isMobile'];
  }

  get isTouch() {
    return this.$store.getters['layout/isTouch'];
  }

  get totalImagesToDisplay() {
    if (this.isMobile) {
      return 8;
    }
    if (this.screenHeight < reducedImagesDisplayDesktopHeight) {
      return 3;
    }
    return 7;
  }

  numProcessed: number = 0;

  currentlyProcessing: number = 0;

  randValue: number = Math.floor(Math.random() * this.copyHeadlines.length);

  scanningDone: boolean = false;

  screenHeight: number = 0;

  get scanningAnimationDuration() {
    return 4;
    /*
    max: 5 sec
    min: 2 sec
     */
    // const numImages = Math.min(this.selectedImageCount, this.totalImagesToDisplay);
    //
    // return Math.min(5, Math.floor(6 - (numImages / 3)));
  }

  get nextNavigationText() {
    return 'Add A Song';
  }

  get navigationDisabled() {
    return this.currentlyProcessing > 0;
  }

  get selectedImageCount() {
    if (this.selectedImages) {
      return this.selectedImages.length;
    }
    return 0;
  }

  get navigationHighlighted() {
    return this.selectedImageCount > 0 && this.currentlyProcessing === 0;
  }

  get copyHeadlines() {
    if (this.selectedImageCount === 1) {
      return [
        'Wow! That is one good lookin\' pic!',
        'This pic will look great in your ads.',
        'Your image is ready to go.',
        // 'Nice pic! Is your other career in modeling?', // we aren't detecting faces yet
        `Nice work! ${getUGC('releaseGenre')} fans are going to love this pic!`,
      ];
    }
    return [
      'Wow! Those are some good lookin\' pics!',
      'These pics will look great in your ads.',
      'Your images are ready to go.',
      // 'Nice pics! Is your other career in modeling?', // we aren't detecting faces yet
      `Nice work! ${getUGC('releaseGenre')} fans are going to love these pics!`,
    ];
  }

  get headlineText() {
    if (this.isMobile) {
      if (this.currentlyProcessing > 0) {
        return 'Analyzing color palette';
      }
    }
    if (this.selectedImageCount > 0 && this.currentlyProcessing === 0) {
      return this.copyHeadlines[this.randValue];
    }
    return 'Feature your images';
  }

  displayedProcessing(imageId: string) {
    return this.getIsImageProcessed(imageId);
  }

  setImageProcessing(imageId: string) {
    this.currentlyProcessing += 1;
    if (this.getIsImageProcessed(imageId)) {
      this.setImageProcessed(imageId);
    }
  }

  setImageProcessed(imageId: string) {
    this.$store.dispatch('onboarding/markImageProcessed', imageId);
    this.numProcessed += 1;
    this.currentlyProcessing -= 1;
  }

  setScreenHeight() {
    this.screenHeight = window.innerHeight;
  }

  beforeCreate() {
    this.$store.dispatch('industry/loadConfigIfNecessary');
    if (this.$route.params.id) {
      this.$store.dispatch('onboarding/load', this.$route.params.id).then(() => {
        this.$store.dispatch('mixpanel/trackOnce', {
          properties: {
            releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
            releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
            domainUrl: this.$store.getters['onboarding/getLandingPageDomains'],
            onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
            referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
          },
          action: 'Zire.ImageOnboardingStepStarted',
        });
      }).catch(() => {
        this.$router.replace({
          name: 'home',
        });
      });
    }
  }

  created() {
    this.setScreenHeight();
    window.addEventListener('resize', this.setScreenHeight);
  }

  mounted() {
    if (this.$route.query?.token) {
      this.$store.dispatch('profile/logInWithBLToken', { token: this.$route.query?.token });
    }
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.setScreenHeight);
  }

  openImageLibraryModal(evt: Event) {
    if (this.currentlyProcessing === 0) {
      evt.preventDefault();
      this.$root.$emit('bv::show::modal', 'image-upload-modal', evt.target);
    }
  }

  handleImagesSelected(images: ImageLibrarySelected) {
    const { selectedImages } = images;
    if (this.selectedImages.map((i) => i.id) !== selectedImages.map((i: UserImage) => i.id)) {
      this.$root.$emit('bv::show::modal', 'updating-campaign-details-modal');
      this.$store.dispatch('onboarding/saveImagesToUGC', selectedImages).then(() => {
        this.$store.dispatch('onboarding/save');
        if (selectedImages.length === 0) {
          this.numProcessed = 0;
        }
      });
    }
  }

  handlePrevClicked() {
    if (this.builderSource === BUILDER_SOURCE_MANUAL) {
      this.$router.push({
        name: 'release-link',
        params: {
          id: this.draft.id,
        },
      });
    } else {
      this.$router.push({
        name: 'review-release',
        params: {
          id: this.draft.id,
        },
      });
    }
  }

  handleNextClicked() {
    this.onSubmit();
  }

  login() {
    this.$root.$emit('bv::show::modal', 'login-modal');
  }

  onSubmit() {
    this.$store.dispatch('mixpanel/trackOnce', {
      properties: {
        releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
        releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
        domainUrl: this.$store.getters['onboarding/getLandingPageDomains'],
        onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
        numberUserImagesApplied: this.$store.getters['onboarding/getNumDraftImages'],
        referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
      },
      action: 'Zire.ImageOnboardingStepCompleted',
    });
    this.$router.push({
      name: 'select-audio',
      params: {
        id: this.draft.id,
      },
    });
  }
}
