

























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import SiteLayout from '@/shared/components/site/SiteLayout.vue';
import SingleRetailerEntry from '@/shared/components/onboarding/SingleRetailerEntry.vue';
import GridLayout from '@/shared/components/builder/GridLayout.vue';
import OnboardingNavigation from '@/shared/components/onboarding/OnboardingNavigation.vue';
import { BUILDER_SOURCE_SEARCH, Draft } from '@/shared/store/onboarding';

@Component({
  mixins: [],
  components: {
    GridLayout,
    SiteLayout,
    SingleRetailerEntry,
    OnboardingNavigation,
  },
})
export default class ReleaseLink extends Vue {
  errors: any = {};

  @Prop({ default: '0' })
  id: string;

  submitAfter: boolean = false;

  landingPage: string = '';

  appleLink: string = '';

  bandlabLink: string = '';

  deezerLink: string = '';

  spotifyLink: string = '';

  validating: boolean = false;

  landingPageIsValid: boolean = true;

  landingPageChecked: boolean = false;

  releaseTypeOptions: string[] = ['Single', 'EP', 'Album'];

  @Getter('industry/genreOptions') genreOptions: string[];

  @Getter('onboarding/getDraft') draftState: Draft;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  touchMap = new WeakMap();

  get nextNavigationText() {
    return 'Add Images';
  }

  loadDraft(id: string) {
    if (id && (id !== '0')) {
      this.$store.dispatch('onboarding/load', id).then(() => {
        const currentDraft = this.draftState;

        currentDraft.landingPages.forEach((i) => {
          switch (i.retailer) {
            case 'apple':
              this.appleLink = i.url;
              break;
            case 'bandlab':
              this.bandlabLink = i.url;
              break;
            case 'deezer':
              this.deezerLink = i.url;
              break;
            case 'spotify':
              this.spotifyLink = i.url;
              break;
            default:
              this.landingPage = i.url;
              break;
          }
        });
      });
    } else {
      const currentDraft = this.draftState;

      currentDraft.landingPages.forEach((i) => {
        switch (i.retailer) {
          case 'apple':
            this.appleLink = i.url;
            break;
          case 'bandlab':
            this.bandlabLink = i.url;
            break;
          case 'deezer':
            this.deezerLink = i.url;
            break;
          case 'spotify':
            this.spotifyLink = i.url;
            break;
          default:
            this.landingPage = i.url;
            break;
        }
      });
    }
  }

  created() {
    this.$store.dispatch('mixpanel/trackOnce', {
      properties: {
        releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
        releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
        onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
        referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
      },
      action: 'Zire.ManualReleaseLinkStepStarted',
    });
  }

  beforeMount() {
    this.$store.dispatch('industry/loadConfigIfNecessary');
    this.loadDraft(this.id);
  }

  mounted() {
    // make sure the mobile dsp search is closed
    if (this.isMobile) {
      this.$root.$emit('bv::hide::modal', 'modal-dsp-search');
    }
  }

  validateLandingPage() {
    if (!this.validating) {
      this.validating = true;
      this.landingPageIsValid = false;
      this.landingPageChecked = false;
      this.$store.dispatch('linkValidation/validate', this.landingPage).then((valid) => {
        this.validating = false;
        this.landingPageIsValid = valid;
        this.landingPageChecked = true;
        if (valid && this.submitAfter) {
          this.onSubmit();
        }
      });
    }
  }

  handlePrevClicked() {
    this.$router.push({
      name: 'about-your-release',
    });
  }

  handleNextClicked() {
    if (!this.landingPageIsValid && this.landingPageChecked) {
      return;
    }
    if (this.landingPageChecked) {
      this.onSubmit();
    } else {
      this.submitAfter = true;
      this.validateLandingPage();
    }
  }

  onSubmit() {
    const landingPagePayload = {
      retailer: 'other',
      url: this.landingPage,
    };
    this.$store.dispatch('onboarding/clearCampaignLandingPages');

    this.$store.dispatch('onboarding/addCampaignLandingPage', landingPagePayload);
    this.$store.dispatch('onboarding/save').then(() => {
      this.$store.dispatch('mixpanel/trackOnce', {
        properties: {
          releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
          releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
          domainUrl: this.$store.getters['onboarding/getLandingPageDomains'],
          onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
          referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
        },
        action: 'Zire.ManualReleaseLinkStepCompleted',
      });

      if (!this.id) {
        this.$router.replace({
          name: 'release-link',
          params: {
            id: this.draftState.id,
          },
        });
      }
      this.$router.push({
        name: 'feature-images',
        params: {
          id: this.draftState.id,
        },
      });
    });
  }

  blurWhenSelected(elementId: string) {
    const elem = document.getElementById(elementId) || null;
    if (elem) {
      elem.blur();
    }
  }

  get submitDisabled() {
    return (this.validating || (!this.landingPageIsValid && this.landingPageChecked));
  }

  get submitHighlighted() {
    return this.landingPageIsValid && this.landingPageChecked;
  }
}
