


























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  mixins: [],
  components: {},
  validations: {},
})
export default class OnboardingNavigation extends Vue {
  @Prop({ default: 'NEXT' })
  nextNavigationText: string;

  @Prop({ default: false })
  allNavigationDisabled: boolean;

  @Prop({ default: false })
  submitDisabled: boolean;

  @Prop({ default: false })
  displayBack: boolean;

  @Prop({ default: true })
  displaySubmit: boolean;

  @Prop({ default: false })
  submitHighlighted: boolean;

  handlePrevClicked() {
    this.$emit('click-prev');
  }

  handleNextClicked() {
    this.$emit('click-next');
  }
}
