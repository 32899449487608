





























































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import HeaderBlock from '@/shared/components/intermission/HeaderBlock.vue';
import ProgressDetails from '@/shared/components/intermission/ProgressDetails.vue';
import ReleaseLinkIcons from '@/shared/components/intermission/ReleaseLinkIcons.vue';
import SnapshotImage from '@/shared/components/intermission/SnapshotImage.vue';
// import { genreCapitalization } from '@/shared/lib/intermission-mappings';

@Component({
  mixins: [],
  components: {
    SnapshotImage,
    HeaderBlock,
    ProgressDetails,
    ReleaseLinkIcons,
  },
})
export default class Release extends Vue {
  @Prop({ default: false })
  active: boolean;

  @Prop({ default: '' })
  releaseGenre: string;

  @Prop({ default: () => [] })
  userImages: any[];

  @Prop({ default: () => [] })
  imagesByTheme: any[];

  hasRun: boolean = false;

  isComplete: boolean = false;

  currentStage: number = 0;

  theme: string = '';

  loadedImages: any = [];

  maxImagesToDisplay: number = 16;

  get maxTinyImagesToDisplay() {
    if (this.isMobile) {
      return 11;
    }
    if (this.isTablet) {
      return 29;
    }
    return 23;
  }

  get isMobile() {
    return this.$store.getters['layout/isMobile'];
  }

  get isTablet() {
    return this.$store.getters['layout/isTablet'];
  }

  get isTouch() {
    return this.$store.getters['layout/isTouch'];
  }

  stages = [
    'user',
    'geo',
    'genre',
    'fan',
    'wallpaper',
  ];

  get isRunning() {
    return (!this.hasRun && this.active);
  }

  get fullImageList() {
    let fullImageList: any = [];
    this.userImages.forEach((image) => {
      fullImageList.push({ theme: 'user', url: image.url });
    });

    // fullImageList = fullImageList.slice(0, 3);

    Object.keys(this.imagesByTheme).forEach(
      (theme: any) => {
        this.imagesByTheme[theme].forEach((creative: any) => {
          fullImageList.push({
            theme,
            url: creative,
          });
        });
      },
    );
    fullImageList = fullImageList.filter((fullImageCreative: any, index: any, fullArray: any) => fullArray.findIndex(
      (creative: any) => fullImageCreative.url === creative.url,
    ) === index);

    return fullImageList;
  }

  get imageBlock() {
    let imageBlock: any = [];
    if (this.currentStage === 0) {
      imageBlock = this.fullImageList.filter((creative: any) => creative.theme === 'user');
    }
    if (this.currentStage > 0) {
      imageBlock = this.fullImageList.filter((creative: any) => creative.theme === 'user');
    }
    if (this.currentStage >= 1) {
      this.fullImageList.forEach((creative: any) => {
        if (creative.theme === 'geo') {
          imageBlock.push(creative);
        }
      });
    }
    if (this.currentStage >= 2) {
      this.fullImageList.forEach((creative: any) => {
        if (creative.theme === 'genre') {
          imageBlock.push(creative);
        }
      });
    }
    if (this.currentStage >= 3) {
      this.fullImageList.forEach((creative: any) => {
        if (creative.theme === 'fan') {
          imageBlock.push(creative);
        }
      });
    }
    if (this.currentStage >= 4) {
      this.fullImageList.forEach((creative: any) => {
        if (creative.theme === 'wallpaper') {
          imageBlock.push(creative);
        }
      });
    }
    return imageBlock.map((creative: any) => creative.url);
  }

  get imagesLeftToLoad() {
    return (this.loadedImages.length < this.imageBlock.length || (this.imageBlock.length === 0));
  }

  get imageBlockTick() {
    const images = this.imageBlock;
    const numImagesToReturn = this.loadedImages.length + 1;
    return images.slice(0, numImagesToReturn);
  }

  get pctCompleteProcessing() {
    return (Math.min(this.loadedImages.length, this.maxImagesToDisplay) / this.maxImagesToDisplay) * 100;
  }

  get processingText() {
    switch (this.currentStage) {
      case 0:
        return ['Processing your images'];
      default:
        return ['Finding imagery from Zire library'];
      // case 1:
      //   return [`Finding images of ${genreCapitalization(this.releaseGenre)} lovin' cities`];
      // case 2:
      //   return [`Finding ${genreCapitalization(this.releaseGenre)} related imagery`];
      // case 3:
      //   return [`Finding images of ${genreCapitalization(this.releaseGenre)} fans`];
      // case 4:
      //   return ['Finding attention-grabbing imagery'];
      // default:
      //   return ['Finishing up...'];
    }
  }

  countForStage(stage: number) {
    let count = 0;
    if (this.fullImageList.length === 0 || this.fullImageList.filter((creative: any) => creative.theme !== 'user').length === 0) {
      return -1;
    }
    switch (stage) {
      case 0:
        return this.fullImageList.filter((creative: any) => creative.theme === 'user').length;
      case 1:
        this.fullImageList.forEach((creative: any) => {
          if (creative.theme === 'geo') {
            count += 1;
          }
        });
        return count;
      case 2:
        this.fullImageList.forEach((creative: any) => {
          if (creative.theme === 'genre') {
            count += 1;
          }
        });
        return count;
      case 3:
        this.fullImageList.forEach((creative: any) => {
          if (creative.theme === 'fan') {
            count += 1;
          }
        });
        return count;
      case 4:
        this.fullImageList.forEach((creative: any) => {
          if (creative.theme === 'wallpaper') {
            count += 1;
          }
        });
        return count;
      default:
        return -1;
    }
  }

  checkEmptyThemeBlock() {
    if (this.currentStage < this.stages.length) {
      if (this.countForStage(this.currentStage) === 0) {
        this.currentStage += 1;
      }
    }
  }

  imageLoaded(url: string) {
    const imageBounceDelay = this.loadedImages >= this.maxImagesToDisplay ? 25 : 300;
    setTimeout(() => {
      this.loadedImages.push(url);
      this.checkAllLoaded();
    }, imageBounceDelay);
  }

  checkAllLoaded() {
    if ((!this.imagesLeftToLoad || this.loadedImages.length > this.maxImagesToDisplay)) {
      if (this.currentStage >= (this.stages.length - 1)) {
        this.isComplete = true;
        setTimeout(() => {
          this.hasRun = true;
          this.$emit('processing-complete');
        }, 1500);
      } else {
        this.currentStage += 1;
        this.checkEmptyThemeBlock();
        this.checkAllLoaded();
      }
    }
  }

  loaded() {}

  updated() {
    this.checkEmptyThemeBlock();
  }

  beforeDestroy() {}
}
