

























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import HeaderBlock from '@/shared/components/intermission/HeaderBlock.vue';
import ProgressDetails from '@/shared/components/intermission/ProgressDetails.vue';
import ReleaseLinkIcons from '@/shared/components/intermission/ReleaseLinkIcons.vue';
import { genreToDomainsMap, genreToLocationsMap, genreCapitalization } from '@/shared/lib/intermission-mappings';

@Component({
  mixins: [],
  components: {
    HeaderBlock,
    ProgressDetails,
    ReleaseLinkIcons,
  },
})
export default class Release extends Vue {
  @Prop({ default: false })
  active: boolean;

  @Prop({ default: '' })
  artistName: string;

  @Prop({ default: '' })
  releaseName: string;

  @Prop({ default: '' })
  releaseType: string;

  @Prop({ default: '' })
  releaseGenre: string;

  @Prop({ default: '' })
  imageUrl: string;

  @Prop({ default: () => [] })
  releaseLandingPages: any[];

  @Getter('retailerSearch/getSelectedArtwork') artworkUrl: string | undefined;

  hasRun: boolean = false;

  get isRunning() {
    if ((!this.hasRun && this.active)) {
      return true;
    }
    return false;
  }

  get isComplete() {
    return this.citiesComplete && this.sitesComplete && this.fansComplete;
  }

  citiesComplete: boolean = false;

  sitesComplete: boolean = false;

  fansComplete: boolean = false;

  sitesIsPaused: boolean = true;

  fansIsPaused: boolean = true;

  get genreLocations() {
    return [`Finding ${genreCapitalization(this.releaseGenre)} lovin' cities`];
  }

  get locationItems() {
    if (this.releaseGenre) {
      return genreToLocationsMap[this.releaseGenre];
    }
    return [];
  }

  get genreSites() {
    return ['Selecting the best music sites'];
  }

  get sitesItems() {
    if (this.releaseGenre) {
      return genreToDomainsMap[this.releaseGenre];
    }
    return [];
  }

  get genreFans() {
    return [`Finding ${genreCapitalization(this.releaseGenre)} fans`];
  }

  get fanItems() {
    return [];
  }

  markCitiesComplete() {
    this.citiesComplete = true;
    this.sitesIsPaused = false;
    this.checkAllComplete();
  }

  markSitesComplete() {
    this.sitesComplete = true;
    this.fansIsPaused = false;
    this.checkAllComplete();
  }

  markFansComplete() {
    this.fansComplete = true;
    this.checkAllComplete();
  }

  checkAllComplete() {
    if (this.citiesComplete && this.sitesComplete && this.fansComplete) {
      setTimeout(() => {
        this.hasRun = true;
        this.$emit('processing-complete');
      }, 1500);
    }
  }

  loaded() {}

  updated() {}

  beforeDestroy() {}
}
