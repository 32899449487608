var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GridLayout',{attrs:{"body-layout-class":"onboarding"}},[_c('template',{slot:"default"},[_c('div',{staticClass:"onboarding-body"},[_c('transition',{attrs:{"name":"animate--component","mode":"out-in","appear":""}},[_c('div',{key:"release-details",staticClass:"onboarding-step release-info"},[_c('div',{staticClass:"header"},[_c('h1',[_vm._v(" Tell Us About Your Release ")])]),_c('form',{staticClass:"release-entry form-field"},[_c('MaterialInputField',{ref:"artistNameInput",staticClass:"form-field__control form-field__control__dark double-column single-column-mobile",attrs:{"element-id":"artistNameInput","element-ref":"artistNameInput","input-classes":{
                'form-field__input form-field__input__dark': true,
                'is-invalid': _vm.$v.artistName.$dirty && _vm.$v.artistName.$invalid
              },"type":"text","autofocus":!_vm.isMobile && !_vm.isTablet,"autocomplete":"off","placeholder":"Artist Name","label":"Artist Name","label-classes":"form-field__label form-field__label__dark","show-error":(_vm.$v.artistName.$dirty && _vm.$v.artistName.$invalid) || _vm.errors.artistName.length > 0,"error-notification-classes":"error-notification form-error error-notification__dark","error-text":"Please enter an artist name"},on:{"input":function($event){_vm.delayTouchUntilBlur(_vm.$v.artistName);_vm.errors.artistName=[];},"blur":function($event){return _vm.touchElement(_vm.$v.artistName)}},model:{value:(_vm.$v.artistName.$model),callback:function ($$v) {_vm.$set(_vm.$v.artistName, "$model", $$v)},expression:"$v.artistName.$model"}}),_c('MaterialInputField',{staticClass:"form-field__control form-field__control__dark double-column single-column-mobile",attrs:{"element-id":"releaseTitleInput","element-ref":"releaseTitleInput","input-classes":{
                'form-field__input form-field__input__dark': true,
                'is-invalid': _vm.$v.releaseName.$dirty && _vm.$v.releaseName.$invalid
              },"type":"text","autofocus":false,"autocomplete":"off","placeholder":"Release Title","label":"Release Title","label-classes":"form-field__label form-field__label__dark","show-error":(_vm.$v.releaseName.$dirty && _vm.$v.releaseName.$invalid) || _vm.errors.releaseName.length > 0,"error-notification-classes":"error-notification form-error error-notification__dark","error-text":"Please enter a release title"},on:{"input":function($event){_vm.delayTouchUntilBlur(_vm.$v.releaseName);_vm.errors.releaseName=[];},"blur":function($event){return _vm.touchElement(_vm.$v.releaseName)}},model:{value:(_vm.$v.releaseName.$model),callback:function ($$v) {_vm.$set(_vm.$v.releaseName, "$model", $$v)},expression:"$v.releaseName.$model"}}),_c('SelectDropdown',{staticClass:"form-field__control form-field__control__dark double-column",attrs:{"element-id":"releaseType","options":_vm.releaseTypeOptions,"label":"Release Type","autofocus":false,"show-error":_vm.$v.releaseType.$dirty && _vm.$v.releaseType.$invalid,"error-text":"Please select a release type","select-classes":{
                'form-field__input form-field__input__dark': true,
                'default-value': _vm.releaseType === '',
                'has-error': _vm.$v.releaseType.$dirty && _vm.$v.releaseType.$invalid,
              },"label-classes":"form-field__label form-field__label__dark","error-notification-classes":"error-notification form-error error-notification__dark"},on:{"blur":function($event){return _vm.touchElement(_vm.$v.releaseType)},"change":function($event){return _vm.blurWhenSelected('releaseType')}},model:{value:(_vm.$v.releaseType.$model),callback:function ($$v) {_vm.$set(_vm.$v.releaseType, "$model", $$v)},expression:"$v.releaseType.$model"}}),_c('SelectDropdown',{staticClass:"form-field__control form-field__control__dark double-column",attrs:{"element-id":"releaseGenre","options":_vm.genreOptions,"label":"Release Genre","autofocus":false,"show-error":_vm.$v.releaseGenre.$dirty && _vm.$v.releaseGenre.$invalid,"error-text":"Please select a release genre","select-classes":{
                'form-field__input form-field__input__dark': true,
                'default-value': _vm.releaseGenre === '',
                'has-error': _vm.$v.releaseGenre.$dirty && _vm.$v.releaseGenre.$invalid,
              },"label-classes":"form-field__label form-field__label__dark","error-notification-classes":"error-notification form-error error-notification__dark"},on:{"blur":function($event){return _vm.touchElement(_vm.$v.releaseGenre)},"change":function($event){return _vm.blurWhenSelected('releaseGenre')}},model:{value:(_vm.$v.releaseGenre.$model),callback:function ($$v) {_vm.$set(_vm.$v.releaseGenre, "$model", $$v)},expression:"$v.releaseGenre.$model"}})],1)])])],1)]),_c('template',{slot:"navigation"},[_c('OnboardingNavigation',{attrs:{"next-navigation-text":_vm.nextNavigationText,"submit-highlighted":_vm.submitHighlighted,"all-navigation-disabled":false,"display-back":true},on:{"click-prev":_vm.handlePrevClicked,"click-next":_vm.handleNextClicked}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }