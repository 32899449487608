




































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Release from '@/shared/components/intermission/Release.vue';
import Images from '@/shared/components/intermission/Images.vue';
import Audio from '@/shared/components/intermission/Audio.vue';
import LoadingIndicator from '@/shared/components/common/LoadingIndicator.vue';
import { Ads } from '@/shared/store/recommended_ads';
import Creative from '@/shared/components/common/Creative.vue';

@Component({
  mixins: [],
  components: {
    LoadingIndicator,
    Release,
    Images,
    Audio,
    Creative,
  },
})
export default class Intermission extends Vue {
  @Prop({ default: '' })
  artistName: string;

  @Prop({ default: '' })
  releaseName: string;

  @Prop({ default: '' })
  releaseType: string;

  @Prop({ default: '' })
  releaseGenre: string;

  @Prop({ default: '' })
  imageUrl: string;

  @Prop({ default: () => [] })
  imagesByTheme: any[];

  @Prop({ default: () => [] })
  userImages: any[];

  @Prop({ default: () => [] })
  releaseLandingPages: any[];

  @Getter('onboarding/draftHasAudio') draftHasAudio: boolean;

  @Getter('onboarding/getNumDraftAudio') numAudio: number;

  @Getter('onboarding/getDraftAudioSourceUrl') audioSourceUrl: string;

  @Getter('onboarding/getDraftAudioStartTimeMs') audioSourceStartTimeMs: number;

  @Getter('recommendedAds/getAds') ads: Ads;

  releaseActive: boolean = false;

  imagesActive: boolean = false;

  audioActive: boolean = false;

  displaySubmitButton: boolean = false;

  headerText: string = 'Building your campaign';

  headerTextSecondLine: string = '';

  creativePreloadIndex: number = 0;

  get creative() {
    const creativeList = this.ads.local.concat(this.ads.playable, this.ads.feedback, this.ads.click);
    return creativeList[this.creativePreloadIndex];
  }

  currentCreativeLoaded() {
    setTimeout(() => {
      this.creativePreloadIndex += 1;
    }, 25);
  }

  mounted() {
    setTimeout(() => {
      this.releaseActive = true;
    }, 250);
  }

  showCampaigns() {
    this.$emit('intermission-complete');
  }

  delayDisplaySubmitButton() {
    setTimeout(() => {
      this.displaySubmitButton = true;
    }, 500);
  }

  processedStep(step: string) {
    switch (step) {
      case 'release':
        this.releaseActive = false;
        this.imagesActive = true;
        this.$emit('intermission-release-complete');
        break;
      case 'images':
        this.imagesActive = false;
        this.audioActive = true;
        this.$emit('intermission-images-complete');
        if (this.numAudio === 0) {
          this.headerText = 'Hooray!';
          this.headerTextSecondLine = ' Your campaign is ready.';
          this.delayDisplaySubmitButton();
        }
        break;
      case 'audio':
        this.audioActive = false;
        this.delayDisplaySubmitButton();
        this.headerText = 'Hooray!';
        this.headerTextSecondLine = ' Your campaign is ready.';
        // this.$emit('intermission-complete');
        break;
      default:
        this.$emit('intermission-default');
    }
  }
}
