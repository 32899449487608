




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import LoadingIndicator from '@/shared/components/common/LoadingIndicator.vue';

@Component({
  mixins: [],
  components: {
    LoadingIndicator,
  },
})
export default class HeaderBlock extends Vue {
  @Prop({ default: true })
  building: boolean;

  @Prop({ default: false })
  isComplete: boolean;

  @Prop({ default: '' })
  title: string;

  @Prop({ default: false })
  hasRun: boolean;

  @Prop({ default: '' })
  initialText: string;

  @Prop({ default: '' })
  completeText: string;

  @Prop({ default: '' })
  buildingText: string;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  loaded() {}

  mounted() {}

  beforeDestroy() {}
}
