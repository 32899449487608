












































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import SiteLayout from '@/shared/components/site/SiteLayout.vue';
import GridLayout from '@/shared/components/builder/GridLayout.vue';

import ReleaseSearch from '@/shared/components/onboarding/ReleaseSearch.vue';
import ModalDspSearch from '@/shared/components/onboarding/ModalDspSearch.vue';

@Component({
  mixins: [],
  components: {
    ModalDspSearch,
    GridLayout,
    SiteLayout,
    ReleaseSearch,
  },
  validations: {},
})
export default class AboutYourRelease extends Vue {
  search: string = '';

  @Getter('layout/isMobile') isMobile: boolean;

  created() {
    this.$store.dispatch('retailerSearch/clearSearch');
    this.$store.dispatch('onboarding/clear').then(() => {
      if (this.$route.query.coupon) {
        this.$store.dispatch('onboarding/setCouponCode', this.$route.query.coupon);
      }
    });
  }

  mounted() {
    this.$store.dispatch('mixpanel/trackOnce', {
      properties: {
        referringDiscountCode: this.$route.query.coupon,
      },
      action: 'Zire.SearchReleaseStepStarted',
    });
  }

  openModalForMobile(evt: any) {
    if (this.isMobile) {
      evt.target.blur();
      this.$root.$emit('bv::show::modal', 'modal-dsp-search');
    }
  }

  scrollToTop() {
    const bodyElement = document.body;
    bodyElement.scrollIntoView(true);
  }
}
