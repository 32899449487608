

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColorPalette } from '@/shared/lib/imgixFunctions';

@Component({
  mixins: [],
  components: {},
  props: {},
})

export default class ImageColorPalette extends Vue {
  @Prop({ default: () => {} })
  colorPalette: ColorPalette;

  @Prop({ default: true })
  showLoadingPalette: boolean;

  @Prop({ default: 3 })
  animationDuration: number;

  numToDisplay: number = 0;

  maxToDisplay: number = 6;

  get fasterAnimationDuration() {
    if (this.animationDuration > 1) {
      return this.animationDuration - 1;
    }
    return 0.5;
  }

  get animationDurationSec() {
    return `${this.fasterAnimationDuration / 6}s`;
  }

  increaseNumToDisplay() {
    window.setTimeout(() => {
      this.numToDisplay += 1;
      if (this.numToDisplay < this.maxToDisplay) {
        this.increaseNumToDisplay();
      }
    }, (this.fasterAnimationDuration * 1000) / 6);
  }

  key(index: number) {
    return index;
  }

  mounted() {
    if (this.showLoadingPalette) {
      this.increaseNumToDisplay();
    } else {
      this.numToDisplay = this.maxToDisplay;
    }
  }

  get colorlist() {
    if (!this.showLoadingPalette) {
      return [
        this.colorPalette.color1,
        this.colorPalette.color2,
        this.colorPalette.color3,
        this.colorPalette.color4,
        this.colorPalette.color5,
        this.colorPalette.color6,
      ];
    }
    return [
      this.colorPalette.color1,
      this.colorPalette.color2,
      this.colorPalette.color3,
      this.colorPalette.color4,
      this.colorPalette.color5,
      this.colorPalette.color6,
    ].slice(0, this.numToDisplay);
  }
}
