





















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Route } from 'vue-router';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import WaveSurfer from 'wavesurfer.js';
// @ts-ignore
import RegionPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min';
import { BUILDER_SOURCE_SEARCH, Draft } from '@/shared/store/onboarding';

import GridLayout from '@/shared/components/builder/GridLayout.vue';
import AudioUploadProgressComponent from '@/shared/components/common/AudioUploadProgressComponent.vue';
import LoadingIndicator from '@/shared/components/common/LoadingIndicator.vue';
import defaultToast from '@/shared/lib/defaultToast';
import MaterialInputField from '@/shared/components/form/MaterialInputField.vue';

import { isCanceled } from '@/shared/firebase';
import UploaderAudio from '@/shared/components/form/UploaderAudio.vue';
import OnboardingNavigation from '@/shared/components/onboarding/OnboardingNavigation.vue';
import { Operation_Status as OperationStatus, UserAudio, UserAudioSource } from '@/shared/gen/messages.pisa';
import { ErrorDetailTooLong, ErrorDetailTooShort } from '@/shared/store/audioLibrary';
import { FileExceedsMaximumAllowedSizeError } from '@/shared/store/upload';
import { maxAudioFileSizeInMb } from '@/shared/lib/defaultFileConstraints';

@Component({
  mixins: [validationMixin],
  components: {
    GridLayout,
    UploaderAudio,
    OnboardingNavigation,
    AudioUploadProgressComponent,
    LoadingIndicator,
    MaterialInputField,
  },
  validations: {
    songName: { required },
  },
})
export default class SelectAudio extends Vue {
  $refs!: {
    siteLayout: any,
    songNameInput: any,
    uploadedAudio?: HTMLSelectElement,
  };

  @Getter('onboarding/getDraft') draft: Draft;

  @Getter('onboarding/getDraftAudio') draftAudio: Array<UserAudio>;

  @Getter('onboarding/getNumDraftAudio') numAudio: number;

  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  @Getter('profile/userId') userId: string;

  @Getter('upload/activeUploads') currentUploads: string[];

  @Getter('upload/uploadFileName') uploadFileNameFn: (id: string) => string;

  @Getter('upload/uploadPercentage') uploadPercentageFn: (id: string) => number;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  isLoading: boolean = true;

  showError: boolean = true;

  addNewAudioMode: boolean = false;

  uploadInProgress: boolean = false;

  uploadID: string = '';

  previewPlaying: boolean = false;

  source: any = {};

  selectedSourceId: string = '';

  currentPage: number = 1;

  maxPage: number = 0;

  audioLists: UserAudio[] = [];

  songName: string = '';

  wavesurfer: any = null;

  startTimeMs: number = -1;

  get selectedUserAudio() {
    return this.audioLists.find((i) => i.id === this.selectedSourceId);
  }

  get allNavDisabled() {
    return (this.uploadInProgress && !this.fileAttachedToDraft);
  }

  get submitDisabled() {
    return this.allNavDisabled || (this.fileAttachedToDraft && (this.$v.$anyError || !this.songName));
  }

  get fileAttachedToDraft() {
    return Object.keys(this.source).length > 0;
  }

  formatSeconds(time: number) {
    const numHours = Math.floor(time / 3600);
    const numHoursText = `0${numHours}`.slice(-2);
    const numMinutes = `0${Math.floor(time / 60)}`.slice(-2);
    const numSeconds = `0${time % 60}`.slice(-2);
    if (numHours > 0) {
      return `${numHoursText}:${numMinutes}:${numSeconds}`;
    }
    return `${numMinutes}:${numSeconds}`;
  }

  displayPreviewTime(audio: any) {
    const startTimeS = Math.round(audio.startMs / 1000) || 0;
    const duration = Math.round(audio.source.durationMs / 1000);
    const endTimeS = Math.min(startTimeS + 30, duration);

    return `${this.formatSeconds(startTimeS)} - ${this.formatSeconds(endTimeS)}`;
  }

  beforeCreate() {
    this.$store.dispatch('industry/loadConfigIfNecessary');
  }

  mounted() {
    if (this.$route.query?.token) {
      this.$store.dispatch('profile/logInWithBLToken', { token: this.$route.query?.token });
    }
  }

  beforeDestroy() {
    this.uploadID = '';
  }

  @Watch('isLoggedInWithEmail', { immediate: true })
  reloadState(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.loadState();
    }
  }

  loadState() {
    const audioLists = this.$store.dispatch('audioLibrary/getUploadedAudio', { pagination: { page: this.currentPage, perPage: 1000 } });
    let loadDraft = Promise.resolve();
    if (this.$route.params.id) {
      loadDraft = this.$store.dispatch('onboarding/load', this.$route.params.id).catch(() => {
        this.$router.replace({
          name: 'home',
        });
      });
    }
    Promise.all([audioLists, loadDraft]).then((responses) => {
      this.maxPage = responses[0].pagination.totalPages;
      this.audioLists = this.audioLists.concat(responses[0].userAudio);
      if (this.draftAudio && this.draftAudio.length > 0) {
        this.source = this.draftAudio[0].source;
        this.songName = this.source.name;
        this.selectedSourceId = this.draftAudio[0].id;
        this.startTimeMs = this.draftAudio[0].startMs ? this.draftAudio[0].startMs : 0;
        if (this.addNewAudioMode) {
          this.$nextTick(() => {
            this.renderWaveform(this.source);
          });
        }
      }
    }).finally(() => {
      this.isLoading = false;
      this.$store.dispatch('mixpanel/trackOnce', {
        properties: {
          releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
          releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
          domainUrl: this.$store.getters['onboarding/getLandingPageDomains'],
          onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
          numberUserImagesApplied: this.$store.getters['onboarding/getNumDraftImages'],
        },
        action: 'Zire.SongOnboardingStepStarted',
      });
    });
  }

  handleSelectAudio() {
    const el = this.$refs.uploadedAudio;
    if (el) {
      el.blur();
      Vue.toasted.clear();
      if (this.selectedUserAudio) {
        this.songName = this.selectedUserAudio.source.name;
        this.source = this.selectedUserAudio.source;
      }
    }
  }

  get nextNavigationText() {
    return 'Build My Campaign';
  }

  get uploadFileName(): string {
    return this.uploadFileNameFn(this.currentUploads[0]);
  }

  get uploadPercentage(): number {
    return this.uploadPercentageFn(this.currentUploads[0]);
  }

  delayTouchUntilBlur($v: any) {
    $v.$reset();
  }

  touchElement($v: any) {
    $v.$touch();
  }

  resetElement($v: any) {
    $v.$reset();
  }

  uploadAudio(file?: File) {
    if (file) {
      Vue.toasted.clear();
      this.addNewAudioMode = true;
      this.uploadID = '';
      this.source = {};
      this.songName = '';
      this.uploadInProgress = true;
      let localUploadID: string;
      this.$store.dispatch('audioLibrary/upload', file)
        .then(([id, promise]) => {
          localUploadID = id;
          this.uploadID = id;
          return promise;
        })
        .then((source: UserAudioSource) => {
          if (localUploadID !== this.uploadID) {
            return;
          }
          this.source = source;
          if (source.name) {
            this.songName = source.name;
          }
          this.startTimeMs = -1;
          this.$nextTick(() => {
            this.renderWaveform(source);
          });
        })
        .catch((reason) => {
          this.addNewAudioMode = false;
          if (localUploadID !== this.uploadID) {
            return;
          }
          if (isCanceled(reason)) {
            return;
          }
          let errMsg = 'Failed to upload song.';
          if (reason === FileExceedsMaximumAllowedSizeError) {
            errMsg = `Song files cannot be larger than ${maxAudioFileSizeInMb} MB`;
          } else if (reason && reason.operation && reason.operation.status === OperationStatus.ERROR) {
            if (reason.operation.detail === ErrorDetailTooShort) {
              errMsg = 'Songs can not be shorter than 30 seconds.';
            } else if (reason.operation.detail === ErrorDetailTooLong) {
              errMsg = 'Songs can not be longer than 10 minutes.';
            } else {
              // TODO: Show error because audio was an unknown/invalid format...
            }
          }
          defaultToast(errMsg);
        })
        .finally(() => {
          if (localUploadID !== this.uploadID) {
            return;
          }
          this.uploadInProgress = false;
        });
    }
  }

  renderWaveform(audio: any) {
    this.wavesurfer = WaveSurfer.create({
      container: '#waveform',
      progressColor: '#42FFD6',
      height: 64,
      // maxCanvasWidth: 640,
      cursorColor: 'transparent',
      minPxPerSec: 10,
      pixelRatio: 1,
      normalize: true,
      interact: false,
      backend: 'MediaElement',
      responsive: true,
      plugins: [
        RegionPlugin.create(),
      ],
    });

    this.wavesurfer.load(audio.url);
    if (audio.startMs) {
      this.startTimeMs = audio.startMs;
    }
    this.wavesurfer.on('ready', () => {
      this.$nextTick(() => {
        const startTime: number = this.startTimeMs === -1 ? (Math.round(audio.durationMs / 1000) / 2) - 15 : Math.round(this.startTimeMs / 1000);
        this.wavesurfer.addRegion({
          id: 'preview',
          start: startTime,
          end: startTime + 30,
          color: 'rgba(66, 255, 214, 0.5)',
          regionHighlight: true,
          drag: true,
          resize: false,
          attributes: { highlight: true },
        });
        this.wavesurfer.seekTo(this.selectedStartMs() / this.source.durationMs);
      });
    });
    this.wavesurfer.on('finish', () => {
      this.wavesurfer.setCursorColor('transparent');
      this.previewPlaying = false;
    });
    this.wavesurfer.on('pause', () => {
      // this.wavesurfer.setCursorColor('transparent');
      this.previewPlaying = false;
    });
    this.wavesurfer.on('audioprocess', () => {
      const { end } = this.wavesurfer.regions.list.preview;
      const currentTime = this.wavesurfer.getCurrentTime();
      if (currentTime >= end) {
        this.wavesurfer.pause();
        this.wavesurfer.seekTo(this.selectedStartMs() / this.source.durationMs);
      }
    });
    this.wavesurfer.on('region-updated', () => {
      this.wavesurfer.setCursorColor('transparent');
      this.wavesurfer.pause();
      this.wavesurfer.seekTo(this.selectedStartMs() / this.source.durationMs);
    });
  }

  handlePrevClicked() {
    this.showError = false;
    if (this.source) {
      this.deleteAudio();
    }
    this.$store.dispatch('onboarding/save').then(() => {
      this.$router.push({
        name: 'feature-images',
        params: {
          id: this.draft.id,
        },
      });
    });
  }

  selectedStartMs() {
    if (this.addNewAudioMode && this.wavesurfer && this.wavesurfer.regions && this.wavesurfer.regions.list.preview) {
      return Math.round(this.wavesurfer.regions.list.preview.start) * 1000;
    }
    return 0;
  }

  handleNextClicked() {
    if (this.submitDisabled) {
      return;
    }
    if (this.addNewAudioMode) {
      const source = new UserAudioSource(this.source);
      source.name = this.songName;

      const userAudio = new UserAudio({
        source,
        startMs: this.selectedStartMs(),
        durationMs: Math.min(this.source.durationMs, 30000),
      });
      this.$store.dispatch('audioLibrary/updateAudio', source)
        .then(() => this.$store.dispatch('onboarding/completeOnboardingAndSave'))
        .then(() => {
          this.$store.dispatch('onboarding/createPreview', userAudio);
          this.onSubmit(); // Advance to the next step even though createPreview is still in progress...
        });
    } else {
      let promise = Promise.resolve();
      if (this.selectedUserAudio) {
        promise = this.$store.dispatch('onboarding/saveUserAudioToUGC', [this.selectedUserAudio]);
      }
      promise
        .then(() => this.$store.dispatch('onboarding/completeOnboardingAndSave'))
        .then(() => {
          this.onSubmit();
        });
    }
  }

  onSubmit() {
    this.$store.dispatch('mixpanel/trackOnce', {
      properties: {
        releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
        releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
        domainUrl: this.$store.getters['onboarding/getLandingPageDomains'],
        onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
        numberUserImagesApplied: this.$store.getters['onboarding/getNumDraftImages'],
        hasAudioApplied: this.$store.getters['onboarding/draftHasAudio'],
      },
      action: 'Zire.SongOnboardingStepCompleted',
    });

    this.$router.push({
      name: 'intermission',
      params: {
        id: this.draft.id,
      },
    });
  }

  beforeRouteLeave(to: Route, from: Route, next: any) {
    if (to.name !== 'intermission') {
      if (this.uploadInProgress) {
        this.cancelUpload();
      }
    }
    next();
  }

  cancelUpload() {
    this.$store.dispatch('upload/cancelUpload');
    this.uploadInProgress = false;
    this.addNewAudioMode = false;
  }

  playAudio() {
    if (!this.previewPlaying) {
      if (this.wavesurfer.regions.list.preview) {
        this.wavesurfer.setCursorColor('#FFFFFF');
        this.previewPlaying = true;
        const playPromise = this.wavesurfer.playPause();
        if (playPromise && typeof playPromise.then === 'function') {
          playPromise.then(null, () => {
            this.previewPlaying = false;
          });
        }
      }
    } else {
      this.previewPlaying = false;
      const playPromise = this.wavesurfer.playPause();
      if (playPromise && typeof playPromise.then === 'function') {
        playPromise.then(null, () => {
          this.previewPlaying = false;
        });
      }
    }
  }

  deleteAudio() {
    this.$store.dispatch('onboarding/clearAudio')
      .then(() => this.$store.dispatch('onboarding/save'));
    this.source = {};
    this.addNewAudioMode = false;
  }

  login() {
    this.$root.$emit('bv::show::modal', 'login-modal');
  }

  passClickToFileInput(evt: Event) {
    evt.preventDefault();
    document.getElementById('songFileUploader')!.click();
  }
}
