interface GenreMapping {
  [key: string]: string[];
}

export function genreCapitalization(genre: string) {
  switch (genre) {
    case 'EDM':
      return genre;
    case 'R&B':
      return genre;
    default:
      return genre.toLowerCase();
  }
}

export const genreToLocationsMap: GenreMapping = {
  Acoustic: [
    'Omaha',
    'Portland',
    'Raleigh/Durham',
    'Nashville',
    'Memphis',
    'Denver',
    'New York',
    'Los Angeles',
    'Richmond',
    'Austin',
  ],
  Alternative: [
    'Seattle',
    'Las Vegas',
    'Los Angeles',
    'Austin',
    'Milwaukee',
    'New York',
    'Memphis',
    'Philadelphia',
    'Orlando',
    'Chicago',
  ],
  Ambient: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'Salt Lake City',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  Americana: [
    'Las Vegas',
    'Nashville',
    'Dallas',
    'Birmingham',
    'Oklahoma City',
    'Louisville',
    'St. Louis',
    'Cincinnatii',
    'Greenville',
    'New York',
  ],
  'Big Band': [
    'Seattle',
    'Portland',
    'San Francisco',
    'Indianapolis',
    'New Orleans',
    'Minneapolis',
    'Chicago',
    'Memphis',
    'New York',
    'Miami',
  ],
  Bluegrass: [
    'Portland',
    'San Francisco',
    'Seattle',
    'Nashville',
    'Dallas',
    'Houston',
    'Birmingham',
    'Oklahoma City',
    'Louisville',
    'St. Louis',
  ],
  Blues: [
    'Austin',
    'Memphis',
    'New York',
    'New Orleans',
    'Minneapolis',
    'San Francisco',
    'St. Louis',
    'Birmingham',
    'Louisville',
    'Chicago',
  ],
  Bollywood: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'London',
    'Boston',
    'Raleigh/Durham',
    'Washington, DC',
  ],
  Celtic: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'London',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  "Children's Music": [
    'Seattle',
    'Tulsa',
    'Los Angeles',
    'Orlando',
    'Nashville',
    'New York',
    'Miami',
    'Chicago',
    'Las Vegas',
    'Salt Lake City',
  ],
  'Christian/Gospel': [
    'Memphis',
    'Miami',
    'Salt Lake City',
    'Dallas',
    'Oklahoma City',
    'Little Rock',
    'Louisville',
    'Chicago',
    'New York',
    'Los Angeles',
  ],
  'Christian Rock': [
    'Memphis',
    'Miami',
    'Salt Lake City',
    'Dallas',
    'Oklahoma City',
    'Little Rock',
    'Louisville',
    'Chicago',
    'New York',
    'Los Angeles',
  ],
  Classical: [
    'Boston',
    'New York',
    'Nashville',
    'Phoenix',
    'Chicago',
    'Atlanta',
    'Hartford',
    'London',
    'Denver',
    'Paris',
  ],
  Comedy: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'Salt Lake City',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  Country: [
    'Las Vegas',
    'Nashville',
    'Dallas',
    'Birmingham',
    'Oklahoma City',
    'Louisville',
    'St. Louis',
    'Cincinnatii',
    'Greenville',
    'New York',
  ],
  Dance: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'London',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  Disco: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'London',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  Dubstep: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'London',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  EDM: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'Detroit',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  Electronic: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'Boston',
    'Denver',
    'Washington, DC',
    'London',
  ],
  Experimental: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'London',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  'Fitness & Workout': [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'London',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  Folk: [
    'Las Vegas',
    'Denver',
    'Portland',
    'Minneapolis',
    'Salt Lake City',
    'Los Angeles',
    'Phoenix',
    'Asheville',
    'New York',
    'London',
  ],
  Funk: [
    'Seattle',
    'Portland',
    'San Francisco',
    'Los Angeles',
    'Orlando',
    'Tampa',
    'Nashville',
    'New York',
    'Detroit',
    'New Orleans',
  ],
  'Hip Hop': [
    'Miami',
    'Portland',
    'Los Angeles',
    'Washington, DC',
    'Las Vegas',
    'New York',
    'Chicago',
    'New Orleans',
    'Atlanta',
    'London',
  ],
  Holiday: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'Salt Lake City',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  House: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'Boston',
    'Denver',
    'Washington, DC',
    'London',
  ],
  Indie: [
    'Portland',
    'San Francisco',
    'Los Angeles',
    'Milwaukee',
    'London',
    'Memphis',
    'Las Vegas',
    'Seattle',
    'Detroit',
    'New York',
  ],
  Instrumental: [
    'Seattle',
    'Portland',
    'San Francisco',
    'Indianapolis',
    'New Orleans',
    'Minneapolis',
    'Chicago',
    'Memphis',
    'New York',
    'Miami',
  ],
  Jam: [
    'Seattle',
    'Portland',
    'San Francisco',
    'Los Angeles',
    'Asheville',
    'Tampa',
    'Nashville',
    'New York',
    'Denver',
    'New Orleans',
  ],
  Jazz: [
    'Seattle',
    'Portland',
    'San Francisco',
    'Indianapolis',
    'New Orleans',
    'Minneapolis',
    'Chicago',
    'Memphis',
    'New York',
    'Miami',
  ],
  Karaoke: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'London',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  Latin: [
    'Las Vegas',
    'Los Angeles',
    'New York',
    'Miami',
    'San Antonio',
    'Phoenix',
    'Hartford',
    'Orlando',
    'Madison',
    'Chicago',
  ],
  Metal: [
    'Seattle',
    'Portland',
    'Chicago',
    'Los Angeles',
    'Milwaukee',
    'New York',
    'Hartford',
    'Bismark',
    'Phoenix',
    'Oklahoma City',
  ],
  'New Age': [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'Salt Lake City',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  Pop: [
    'Seattle',
    'Tulsa',
    'Los Angeles',
    'Orlando',
    'Nashville',
    'New York',
    'Miami',
    'Chicago',
    'Las Vegas',
    'Boston',
  ],
  Punk: [
    'Los Angeles',
    'San Francisco',
    'Seattle',
    'Portland',
    'Nashville',
    'Orlando',
    'New York',
    'Hartford',
    'Milwaukee',
    'Washington, DC',
  ],
  'R&B': [
    'Las Vegas',
    'Los Angeles',
    'Memphis',
    'Orlando',
    'New York',
    'Birmingham',
    'Atlanta',
    'Detroit',
    'Indianapolis',
    'St. Louis',
  ],
  Rap: [
    'Miami',
    'Portland',
    'Los Angeles',
    'Washington, DC',
    'Las Vegas',
    'New York',
    'Chicago',
    'Charlotte',
    'New Orleans',
    'Atlanta',
  ],
  Reggae: [
    'Toronto',
    'New York',
    'Kingston',
    'Los Angeles',
    'London',
    'Miami',
    'Atlanta',
    'Chicago',
    'Minneapolis',
    'Washington, DC',
  ],
  Reggaeton: [
    'Las Vegas',
    'Las Angeles',
    'New York',
    'Miami',
    'San Antonio',
    'Phoenix',
    'Hartford',
    'Orlando',
    'Madison',
    'Chicago',
  ],
  Rock: [
    'Seattle',
    'Los Angeles',
    'Minneapolis',
    'New York',
    'Denver',
    'Chicago',
    'Austin',
    'Milwaukee',
    'Memphis',
    'Salt Lake City',
  ],
  Salsa: [
    'Las Vegas',
    'Los Angeles',
    'New York',
    'Miami',
    'San Antonio',
    'Phoenix',
    'Hartford',
    'Orlando',
    'Madison',
    'Chicago',
  ],
  'Singer Songwriter': [
    'Omaha',
    'Portland',
    'Raleigh/Durham',
    'Nashville',
    'Memphis',
    'Denver',
    'New York',
    'Los Angeles',
    'Richmond',
    'Austin',
  ],
  Ska: [
    'Toronto',
    'New York',
    'Kingston',
    'Los Angeles',
    'London',
    'Philadelphia',
    'Miami',
    'Atlanta',
    'Chicago',
    'Minneapolis',
  ],
  Soul: [
    'Memphis',
    'Chicago',
    'St. Louis',
    'New Orleans',
    'New York',
    'Los Angeles',
    'Louisville',
    'Philadelphia',
    'Atlanta',
    'Detroit',
  ],
  Soundtrack: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'Salt Lake City',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  Spiritual: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'London',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  'Spoken Word': [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'Salt Lake City',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  Techno: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'London',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  Trap: [
    'Miami',
    'Portland',
    'Los Angeles',
    'Washington, DC',
    'Las Vegas',
    'New York',
    'Chicago',
    'New Orleans',
    'Atlanta',
    'London',
  ],
  Vocal: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'London',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
  World: [
    'San Francisco',
    'New York',
    'Miami',
    'Las Vegas',
    'Los Angeles',
    'Chicago',
    'London',
    'Boston',
    'Denver',
    'Washington, DC',
  ],
};

export const genreToDomainsMap: GenreMapping = {
  Acoustic: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  Alternative: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Ultimate Guitar',
    'Variety',
    'Loud Wire',
    'The Fader',
    'MTV',
    'iHeartRadio',
  ],
  Ambient: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'EDM.com',
    'Last.fm',
    'Variety',
    'Dancing Astronaut',
    'The Fader',
    'MTV',
    'iHeartRadio',
  ],
  Americana: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Wide Open Country',
    'Variety',
    'The Fader',
    'MTV',
    'Nash Country Daily',
    'iHeartRadio',
  ],
  'Big Band': [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  Bluegrass: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Wide Open Country',
    'Variety',
    'The Fader',
    'MTV',
    'Nash Country Daily',
    'iHeartRadio',
  ],
  Blues: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'American Blues Scene',
    'Last.fm',
    'Variety',
    'Blues Doodle',
    'The Fader',
    'MTV',
    'iHeartRadio',
  ],
  Bollywood: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  Celtic: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  "Children's Music": [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  'Christian/Gospel': [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'CCM Magazine',
    'Variety',
    'The Fader',
    "Today's Christian Music",
    'MTV',
    'iHeartRadio',
  ],
  'Christian Rock': [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'CCM Magazine',
    'Variety',
    'The Fader',
    "Today's Christian Music",
    'MTV',
    'iHeartRadio',
  ],
  Classical: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Classical-music.com',
    'Variety',
    'The Fader',
    'The Violin Channel',
    'MTV',
    'iHeartRadio',
  ],
  Comedy: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  Country: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Wide Open Country',
    'Variety',
    'The Fader',
    'MTV',
    'Nash Country Daily',
    'iHeartRadio',
  ],
  Dance: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'EDM.com',
    'Last.fm',
    'Variety',
    'Dancing Astronaut',
    'The Fader',
    'MTV',
    'iHeartRadio',
  ],
  Disco: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'EDM.com',
    'Last.fm',
    'Variety',
    'Dancing Astronaut',
    'The Fader',
    'MTV',
    'iHeartRadio',
  ],
  Dubstep: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'EDM.com',
    'Last.fm',
    'Variety',
    'Dancing Astronaut',
    'The Fader',
    'MTV',
    'iHeartRadio',
  ],
  EDM: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'EDM.com',
    'Last.fm',
    'Variety',
    'Dancing Astronaut',
    'The Fader',
    'MTV',
    'iHeartRadio',
  ],
  Electronic: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'EDM.com',
    'Last.fm',
    'Variety',
    'Dancing Astronaut',
    'The Fader',
    'MTV',
    'iHeartRadio',
  ],
  Experimental: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'EDM.com',
    'Last.fm',
    'Variety',
    'Dancing Astronaut',
    'The Fader',
    'MTV',
    'iHeartRadio',
  ],
  'Fitness & Workout': [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  Folk: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Folk Radio ',
    'Variety',
    'The Fader',
    'MTV',
    'Folk Alley',
    'iHeartRadio',
  ],
  Funk: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  'Hip Hop': [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'BET',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'XXL',
    'iHeartRadio',
  ],
  Holiday: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  House: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'EDM.com',
    'Last.fm',
    'Variety',
    'Dancing Astronaut',
    'The Fader',
    'MTV',
    'iHeartRadio',
  ],
  Indie: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  Instrumental: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'All About Jazz',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'Jazz Times',
    'iHeartRadio',
  ],
  Jam: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  Jazz: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'All About Jazz',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'Jazz Times',
    'iHeartRadio',
  ],
  Karaoke: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  Latin: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'LaMezcla',
    'Variety',
    'The Fader',
    'MTV',
    'Sounds and Colours',
    'iHeartRadio',
  ],
  Metal: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Decibel',
    'Variety',
    'Loud Wire',
    'The Fader',
    'Blabbermouth',
    'MTV',
    'iHeartRadio',
  ],
  'New Age': [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  Pop: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Idolator',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'Pop Matters',
    'iHeartRadio',
  ],
  Punk: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Ultimate Guitar',
    'Variety',
    'Loud Wire',
    'The Fader',
    'MTV',
    'iHeartRadio',
  ],
  'R&B': [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'ThisisRnB ',
    'Variety',
    'The Fader',
    'MTV',
    'Rated RnB',
    'iHeartRadio',
  ],
  Rap: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'BET',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'XXL',
    'iHeartRadio',
  ],
  Reggae: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Reggaeville',
    'Last.fm',
    'Variety',
    'The Fader',
    'World A Reggae',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  Reggaeton: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'LaMezcla',
    'Variety',
    'The Fader',
    'Sounds and Colours',
    'iHeartRadio',
  ],
  Rock: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Ultimate Guitar',
    'Variety',
    'Loud Wire',
    'The Fader',
    'MTV',
    'iHeartRadio',
  ],
  Salsa: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'LaMezcla',
    'Variety',
    'The Fader',
    'MTV',
    'Sounds and Colours',
    'iHeartRadio',
  ],
  'Singer Songwriter': [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  Ska: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  Soul: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Funk My Soul',
    'Variety',
    'The Fader',
    'MTV',
    "Singer's Room",
    'iHeartRadio',
    'Discogs',
  ],
  Soundtrack: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  Spiritual: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  'Spoken Word': [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  Techno: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'EDM.com',
    'Last.fm',
    'Variety',
    'Dancing Astronaut',
    'The Fader',
    'MTV',
    'iHeartRadio',
  ],
  Trap: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'BET',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'XXL',
    'iHeartRadio',
  ],
  Vocal: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
  World: [
    'Billboard',
    'Pitchfork',
    'Rolling Stone',
    'Last.fm',
    'Variety',
    'The Fader',
    'MTV',
    'iHeartRadio',
    'Discogs',
  ],
};
