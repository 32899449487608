






























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  mixins: [],
  components: {
  },
})
export default class ReleaseLinkIcons extends Vue {
  dspRetailers = ['spotify', 'apple', 'deezer', 'bandlab'];

  @Prop({ default: () => [] })
  providerLinks: any[];

  @Prop({ default: true })
  displayName: boolean;

  @Prop({ default: false })
  iconsOnly: boolean;

  @Prop({ default: true })
  makeLink: boolean;

  @Prop({ default: '' })
  iconTone: string;

  @Prop({ default: false })
  greyscale: boolean;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  get providers() {
    return this.dspRetailers.filter((provider) => Object.keys(this.linksByRetailer).findIndex((element) => element === provider) !== -1);
  }

  get linksByRetailer() {
    const links: any = {};
    this.providerLinks.forEach((provider) => {
      links[provider.retailer] = provider.url;
    });
    return links;
  }

  providerLinkClasses(providerName: string) {
    const classObject: any = {
      disabled: !(this.providers.findIndex((element) => element === providerName) > -1),
    };
    classObject[providerName] = true;
    if (this.greyscale) {
      classObject.greyscale = true;
    }
    return classObject;
  }

  providerTitle(providerName: string) {
    switch (providerName) {
      case 'apple':
        return `${providerName.slice(0, 1).toUpperCase()}${providerName.slice(1)} Music`;
      default:
        return providerName.slice(0, 1).toUpperCase() + providerName.slice(1);
    }
  }

  providerIcon(providerName: string) {
    switch (providerName) {
      case 'apple':
        return ['fab', 'apple'];
      // case 'deezer':
      //   return ['fab', 'sellsy'];
      case 'spotify':
        return ['fab', 'spotify'];
      // case 'other':
      //   return ['far', 'link'];
      default:
        return [];
    }
  }

  retailerLink(retailerName: string) {
    return this.linksByRetailer[retailerName];
  }
}
