





























































































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

import SiteLayout from '@/shared/components/site/SiteLayout.vue';
import GridLayout from '@/shared/components/builder/GridLayout.vue';
import LoadingIndicator from '@/shared/components/common/LoadingIndicator.vue';
import OnboardingNavigation from '@/shared/components/onboarding/OnboardingNavigation.vue';
import SelectDropdown from '@/shared/components/common/SelectDropdown.vue';
import { AlbumResult, SpotifyArtist } from '@/shared/store/retailerSearch';
import { getUGC } from '@/shared/lib/ugc_adaptor';
import { Draft, BUILDER_SOURCE_SEARCH } from '@/shared/store/onboarding';
import { LandingPage as APILandingPage } from '@/shared/gen/messages.pisa';
import ReleaseLinkIcons from '@/shared/components/intermission/ReleaseLinkIcons.vue';
import defaultErrorToast from '@/shared/lib/defaultToast';
import { TwirpError } from '@/shared/gen/twirp';

@Component({
  mixins: [validationMixin],
  components: {
    GridLayout,
    SiteLayout,
    LoadingIndicator,
    OnboardingNavigation,
    ReleaseLinkIcons,
    SelectDropdown,
  },
  validations: {
    releaseGenre: { required },
  },
})
export default class ReviewRelease extends Vue {
  $refs!: {
    releaseGenreSelect: HTMLSelectElement,
    releaseGenreSelectMobile: HTMLSelectElement,
  };

  dspRetailers = ['spotify', 'apple', 'deezer', 'bandlab'];

  showLoading: boolean = true;

  releaseGenreFocus: boolean = false;

  releaseGenreMobileFocus: boolean = false;

  configPromise: Promise<any> = Promise.resolve();

  loadPromise: Promise<any> = Promise.resolve();

  errors: any = {
    releaseGenre: [],
  };

  @Getter('retailerSearch/getSelectedResult') selectedRelease: AlbumResult;

  @Getter('industry/genreOptions') genreOptions: string[];

  @Getter('onboarding/getDraft') draftState: Draft;

  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  @Getter('layout/isMobile') isMobile: boolean;

  @Prop({ default: '0' })
  id: string;

  artistName: string = '';

  releaseName: string = '';

  releaseGenre: string = '';

  releaseType: string = '';

  spotifyArtists: SpotifyArtist[] = [];

  artworkUrl: string = '';

  landingPages: Array<{ retailer: string, url: string }> = [];

  showValidation: boolean = false;

  mounted() {
    // make sure the mobile dsp search is closed
    if (this.isMobile) {
      this.$root.$emit('bv::hide::modal', 'modal-dsp-search');
    }

    this.configPromise = this.$store.dispatch('industry/loadConfigIfNecessary');

    // Make sure the source is cleared.
    this.$store.dispatch('onboarding/updateSource', '');

    if (this.$route.query?.revision_id && !this.selectedRelease) {
      const revId = this.$route.query?.revision_id;
      this.$store.dispatch('retailerSearch/searchBL', revId).then(() => {
        this.$store.dispatch('retailerSearch/setSelectedIndex', 0);
      }, (err: TwirpError) => {
        this.$router.replace({
          name: 'new-campaign',
        });
        if (err.message === 'unexpected HTTP status: 403') {
          defaultErrorToast('', 'private', '', 600000);
        } else {
          defaultErrorToast();
        }
      });
    } else if (this.$route.query?.upc && !this.selectedRelease) {
      const { upc, source } = this.$route.query;
      // Save the source to be retrieved later.
      this.$store.dispatch('onboarding/updateSource', source);
      this.$store.dispatch('retailerSearch/searchUpc', upc).then(() => {
        this.$store.dispatch('retailerSearch/setSelectedIndex', 0);
      }, (err: TwirpError) => {
        this.$router.replace({
          name: 'new-campaign',
        });
        if (err.message === 'unexpected HTTP status: 403') {
          defaultErrorToast('', 'private', '', 600000);
        } else {
          defaultErrorToast();
        }
      });
    } else {
      this.proceedWithSelected();
    }
  }

  @Watch('showLoading')
  watchIsMobile(newVal: boolean, oldVal: boolean) {
    if (!newVal && oldVal) {
      if (this.id === '0') {
        // Wait for the elements to render...
        setTimeout(() => {
          if (!this.releaseGenre) {
            this.$nextTick(() => {
              if (this.$refs.releaseGenreSelect && !this.isMobile) {
                this.releaseGenreFocus = true;
              } else if (this.$refs.releaseGenreSelectMobile) {
                this.releaseGenreMobileFocus = true;
              }
            });
          }
          // 500ms for the animation, 200ms for render on slower device
        }, 700);
      }
    }
  }

  @Watch('selectedRelease')
  proceedWithSelected() {
    if (this.id === '0') {
      if (this.selectedRelease) {
        this.loadPromise = Promise.all([
          new Promise<void>((resolve) => {
            setTimeout(resolve, 2500);
          }),
          new Promise<void>((resolve, reject) => {
            this.artistName = this.selectedRelease.artistName;
            this.releaseName = this.selectedRelease.name;
            this.artworkUrl = this.selectedRelease.artworkURL;
            this.releaseType = this.selectedRelease.albumType.toLowerCase();
            this.releaseType = this.releaseType.slice(0, 1).toUpperCase() + this.releaseType.slice(1);
            this.spotifyArtists = this.selectedRelease.spotifyArtists;

            if (this.releaseType === 'Compilation') {
              this.releaseType = 'Album';
            } else if (this.releaseType === 'Ep') {
              this.releaseType = 'EP';
            }

            if (this.selectedRelease.landingPages.length > 0) {
              let selectedReleasePromise = Promise.resolve(this.selectedRelease);
              if (this.dspRetailers.sort().join(',') !== this.selectedRelease.landingPages.map((i) => i.retailer).sort().join(',')) {
                selectedReleasePromise = this.$store.dispatch('retailerSearch/findAllRetailersForSelected');
              }
              selectedReleasePromise.then((selectedRelease: AlbumResult) => {
                selectedRelease.landingPages.forEach((lp) => {
                  this.landingPages.push({
                    retailer: lp.retailer,
                    url: lp.url,
                  });
                });

                this.configPromise.then(() => {
                  selectedRelease.genres.forEach((g: string) => {
                    if (!this.releaseGenre && this.genreOptions.indexOf(g) !== -1) {
                      this.releaseGenre = g;
                    }
                  });
                });
              }).finally(() => {
                resolve();
              });
            } else {
              reject();
            }
          }),
        ]);
      } else {
        this.loadPromise = Promise.reject();
      }
    } else {
      this.loadPromise = this.$store.dispatch('onboarding/load', this.id).then(() => {
        this.artistName = getUGC('artistName');
        this.releaseName = getUGC('releaseName');
        this.releaseGenre = getUGC('releaseGenre');
        this.releaseType = getUGC('releaseType');
        this.spotifyArtists = JSON.parse(getUGC('spotifyArtists'));

        this.draftState.landingPages.forEach((lp) => {
          this.landingPages.push({
            retailer: lp.retailer,
            url: lp.url,
          });
        });

        if (this.selectedRelease) {
          this.artworkUrl = this.selectedRelease.artworkURL;
        }
      });
    }

    Promise.all([this.configPromise, this.loadPromise]).then(() => {
      this.showLoading = false;
      this.$store.dispatch('mixpanel/trackOnce', {
        properties: {
          releaseType: this.releaseType,
          releaseGenre: this.releaseGenre,
          onboardingType: 'sa_automatic',
          referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
        },
        action: 'Zire.ReviewReleaseDetailsStepStarted',
      });
    }, () => {
      this.$router.replace({
        name: 'new-campaign',
      });
    });
  }

  delayTouchUntilBlur($v: any) {
    $v.$reset();
  }

  touchElement($v: any) {
    $v.$touch();
  }

  resetElement($v: any) {
    $v.$reset();
  }

  get nextNavigationText() {
    return 'Add Images';
  }

  get submitDisabled() {
    return (this.$v.$anyError && this.$v.$anyDirty);
  }

  get providerLinks() {
    return this.landingPages.slice(0).sort((lhs, rhs) => {
      const lhsIndex = this.dspRetailers.indexOf(lhs.retailer);
      const rhsIndex = this.dspRetailers.indexOf(rhs.retailer);
      if (lhsIndex < rhsIndex) {
        return -1;
      }
      if (rhsIndex < lhsIndex) {
        return 1;
      }
      return 0;
    });
  }

  get unavailableRetailers() {
    const unavailableRetailers: any[] = [];
    this.dspRetailers.forEach((retailer) => {
      if (this.landingPages.findIndex((lp) => lp.retailer === retailer) === -1) {
        unavailableRetailers.push({
          retailer,
          url: null,
        });
      }
    });
    return unavailableRetailers;
  }

  get submitHighlighted() {
    return !(this.$v.$anyError && this.$v.$anyDirty)
      && (this.releaseGenre !== '');
  }

  handlePrevClicked() {
    this.$router.replace({
      name: 'new-campaign',
    });
  }

  handleNextClicked() {
    if (this.releaseGenre === '') {
      this.showValidation = true;
      this.$v.releaseGenre!.$touch();
      return;
    }
    this.onSubmit();
  }

  blurWhenSelected() {
    const elem = document.getElementById('releaseGenre') || null;
    if (elem) {
      elem.blur();
    }
  }

  triggerTooltip() {
    const elem = document.getElementById('disclaimer_review_release');
    if (elem) {
      elem.focus();
    }
  }

  onSubmit() {
    /*
    write local payload to draft
    save draft
     */
    const fieldElements = [
      {
        key: 'artistName',
        value: this.artistName,
      }, {
        key: 'releaseName',
        value: this.releaseName,
      }, {
        key: 'releaseGenre',
        value: this.releaseGenre,
      }, {
        key: 'releaseType',
        value: this.releaseType,
      }, {
        key: 'spotifyArtists',
        value: JSON.stringify(this.spotifyArtists),
      }];
    fieldElements.forEach((fieldValue) => {
      this.$store.dispatch('onboarding/updateUGC', {
        Key: fieldValue.key,
        Value: fieldValue.value,
      });
    });

    this.$store.dispatch('onboarding/setImageUrl', this.artworkUrl);

    this.$store.dispatch('onboarding/clearCampaignLandingPages');
    this.landingPages.forEach((lp) => {
      this.$store.dispatch('onboarding/addCampaignLandingPage', new APILandingPage(lp));
    });
    this.$store.dispatch('onboarding/setBuilderSourceType', BUILDER_SOURCE_SEARCH);
    this.$store.dispatch('onboarding/setAccessedThrough', BUILDER_SOURCE_SEARCH);

    this.$store.dispatch('onboarding/save').then(() => {
      this.$store.dispatch('mixpanel/trackOnce', {
        properties: {
          releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
          releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
          domainUrl: this.$store.getters['onboarding/getLandingPageDomains'],
          onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
          referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
        },
        action: 'Zire.ReviewReleaseDetailsStepCompleted',
      });
      if (!this.id) {
        this.$router.replace({
          name: 'review-release',
          params: {
            id: this.draftState.id,
          },
        });
      }
      this.$router.push({
        name: 'feature-images',
        params: {
          id: this.draftState.id,
        },
      });
    });
  }
}
