


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import * as imgixFunctions from '@/shared/lib/imgixFunctions';

@Component({
  mixins: [],
  components: {},
})
export default class SnapshotImage extends Vue {
  @Prop({ default: '' })
  imageUrl: string;

  @Prop({ default: false })
  tiny: boolean;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  getThumbnailUrl(imageUrl: string) {
    return imgixFunctions.getImgixSizeQuerystring(imageUrl, 90, 90);
  }

  loaded() {
    this.$emit('image-loaded', this.imageUrl);
  }

  mounted() {
    if (this.imageUrl === '') {
      this.$emit('image-loaded', this.imageUrl);
    }
  }
}
