var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GridLayout',{attrs:{"body-layout-class":"onboarding"}},[_c('template',{slot:"default"},[_c('transition',{attrs:{"name":"component-fade","mode":"out-in","appear":""}},[(_vm.showLoading)?_c('div',{key:"loading",staticClass:"onboarding-body"},[_c('div',{staticClass:"onboarding-step loading-container"},[(_vm.id === '0')?_c('h1',[_vm._v(" Locating your release on popular retailers ")]):_vm._e(),_c('div',{staticClass:"loading-indicator"},[_c('loading-indicator',{attrs:{"size":"125"}})],1)])]):_vm._e(),(!_vm.showLoading)?_c('div',{key:"release-details",staticClass:"onboarding-body"},[_c('div',{staticClass:"onboarding-step release-info"},[_c('div',{staticClass:"header"},[_c('h1',[_vm._v(" Review your release details ")])]),_c('div',{staticClass:"release-details"},[_c('div',{staticClass:"releaseImage"},[_c('img',{attrs:{"src":_vm.artworkUrl}})]),_c('div',{staticClass:"album-details"},[_c('div',{staticClass:"release-name"},[_vm._v(" "+_vm._s(_vm.releaseName)+" ")]),_c('div',{staticClass:"release-type"},[_vm._v(" "+_vm._s(_vm.releaseType)+" by "+_vm._s(_vm.artistName)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"genre-selection pt-4"},[_c('SelectDropdown',{ref:"releaseGenreSelect",staticClass:"form-field__control",attrs:{"element-id":"releaseGenre","element-ref":"releaseGenreSelect","options":_vm.genreOptions,"tab-index":"4","label":"Release Genre","focus":_vm.releaseGenreFocus,"show-error":_vm.$v.releaseGenre.$dirty && _vm.$v.releaseGenre.$invalid && _vm.showValidation,"error-text":"Please select a release genre","select-classes":{
                    'form-field__input': true,
                    'default-value': _vm.releaseGenre === '',
                    'has-error': _vm.$v.releaseGenre.$dirty && _vm.$v.releaseGenre.$invalid && _vm.showValidation,
                  },"label-classes":"form-field__label form-field__label__dark","error-notification-classes":"error-notification form-error error-notification__dark"},on:{"blur":function($event){return _vm.touchElement(_vm.$v.releaseGenre)},"change":_vm.blurWhenSelected},model:{value:(_vm.$v.releaseGenre.$model),callback:function ($$v) {_vm.$set(_vm.$v.releaseGenre, "$model", $$v)},expression:"$v.releaseGenre.$model"}})],1),(!_vm.isMobile)?_c('a',{staticClass:"hide-mobile",attrs:{"id":"disclaimer_review_release","href":"#main"},on:{"click":_vm.triggerTooltip}},[_c('fa-icon',{staticClass:"disclaimer-text mr-1",staticStyle:{"margin-left":"10px"},attrs:{"icon":['far', 'question-circle']}}),_c('span',{staticClass:"disclaimer-text"},[_vm._v(" How is my genre used in the campaign? ")])],1):_vm._e(),_c('b-tooltip',{attrs:{"target":"disclaimer_review_release","placement":"bottomright","custom-class":"custom-tooltip__review","triggers":"focus","title":"We use your genre to build ads specific to your sound and to make sure they are seen by the right audience."}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],staticClass:"genre-selection pt-4"},[_c('SelectDropdown',{ref:"releaseGenreSelectMobile",staticClass:"form-field__control",attrs:{"element-id":"releaseGenreMobile","element-ref":"releaseGenreSelectMobile","options":_vm.genreOptions,"tab-index":"4","label":"Release Genre","focus":_vm.releaseGenreMobileFocus,"show-error":_vm.$v.releaseGenre.$dirty && _vm.$v.releaseGenre.$invalid && _vm.showValidation,"error-text":"Please select a release genre","select-classes":{
                  'form-field__input': true,
                  'default-value': _vm.releaseGenre === '',
                  'has-error': _vm.$v.releaseGenre.$dirty && _vm.$v.releaseGenre.$invalid && _vm.showValidation,
                },"label-classes":"form-field__label form-field__label__dark","error-notification-classes":"error-notification form-error error-notification__dark"},on:{"blur":function($event){return _vm.touchElement(_vm.$v.releaseGenre)},"change":_vm.blurWhenSelected},model:{value:(_vm.$v.releaseGenre.$model),callback:function ($$v) {_vm.$set(_vm.$v.releaseGenre, "$model", $$v)},expression:"$v.releaseGenre.$model"}}),_c('a',{staticClass:"hide-mobile",attrs:{"id":"disclaimer_review_release","href":"#main"},on:{"click":_vm.triggerTooltip}},[_c('fa-icon',{staticClass:"disclaimer-text mr-1",staticStyle:{"margin-left":"10px"},attrs:{"icon":['far', 'question-circle']}}),_c('span',{staticClass:"disclaimer-text"},[_vm._v(" How is my genre used in the campaign? ")])],1)],1),_c('div',{staticClass:"retailer-details"},[_c('div',{staticClass:"found-on"},[_vm._v(" FOUND ON ")]),_c('ReleaseLinkIcons',{attrs:{"display-name":true,"icons-only":false,"provider-links":_vm.providerLinks,"make-link":true,"icon-tone":"light","greyscale":false}}),_c('ReleaseLinkIcons',{attrs:{"display-name":true,"icons-only":false,"provider-links":_vm.unavailableRetailers,"make-link":true,"icon-tone":"light","greyscale":true}})],1)])])]):_vm._e()])],1),_c('template',{slot:"navigation"},[_c('OnboardingNavigation',{attrs:{"next-navigation-text":_vm.nextNavigationText,"all-navigation-disabled":false,"display-back":!_vm.showLoading,"display-submit":!_vm.showLoading,"submit-highlighted":_vm.submitHighlighted},on:{"click-prev":_vm.handlePrevClicked,"click-next":_vm.handleNextClicked}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }