









































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { UserImage } from '@/shared/gen/messages.pisa';
import * as imgixFunctions from '@/shared/lib/imgixFunctions';

import ImageColorPalette from '@/shared/components/onboarding/ImageColorPalette.vue';
import ImageOverlay from '@/shared/components/builder/ImageOverlay.vue';

@Component({
  mixins: [],
  components: {
    ImageColorPalette,
    ImageOverlay,
  },
})
export default class SelectedImageWithLaser extends Vue {
  @Prop({ default: () => {} })
  image: UserImage;

  @Prop({ default: '0' })
  index: string;

  @Prop({ default: 'remove' })
  overlay: string;

  @Prop({ default: false })
  displayedProcessing: boolean;

  @Prop({ default: 5 })
  animationDuration: number;

  @Prop({ default: false, type: Boolean })
  noOverlay: boolean;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  imageScanning: boolean = false;

  finishedScanning: boolean = false;

  showColorPalette: boolean = false;

  get animationDurationSec() {
    return `${this.animationDuration}s`;
  }

  loaded() {
    if (!this.displayedProcessing) {
      this.$emit('image-processing', this.image.id);
      const jiggleFactor = Math.random() * 1000;
      window.setTimeout(this.scanImages, jiggleFactor);
    } else {
      this.showColorPalette = true;
      this.imageScanning = false;
      this.finishedScanning = true;
    }
  }

  mounted() {}

  beforeDestroy() {
    if (this.imageScanning) {
      this.$emit('image-processed', this.image.id);
    }
  }

  get imageScanningClass() {
    const getterClass = this.isMobile ? 'scanImagesMobile' : 'scanImages';
    return this.imageScanning ? getterClass : '';
  }

  getThumbnailUrl(image: UserImage) {
    return imgixFunctions.getImgixSizeQuerystring(image.url, 300, 300);
  }

  getColorPalette(image: UserImage) {
    return imgixFunctions.extractColorPalette(image.colorPalette);
  }

  displayColors() {
    this.showColorPalette = true;
    window.setTimeout(() => {
      this.imageScanning = false;
      this.finishedScanning = true;
      this.$emit('image-processed', this.image.id);
    }, this.animationDuration * 1000);
  }

  scanImages() {
    this.finishedScanning = false;
    this.imageScanning = true;
    this.showColorPalette = false;
    window.setTimeout(() => {
      this.displayColors();
    }, 100);
  }
}
